<template>
  <hit-base-input
    :label="label"
    :validation-state="validationState"
    :inline-input="inlineInput"
    :inline-label-width="inlineLabelWidth"
    use-custom-height
    :full-width="false"
    :add-top-margin="addTopMargin"
    @focusout="handleBlur"
  >
    <on-click-outside @trigger="handleClickOutside">
      <div class="flex flex-col bg-input border-input border rounded">
        <div class="flex-none border-input border-b p-1">
          <div
            :class="[
              disabled ? 'pointer-events-none' : 'pointer-events-auto',
              'flex flex-wrap items-center justify-start max-w-full',
            ]"
          >
            <div class="flex items-center justify-start mr-4">
              <hit-icon
                icon="format_h1"
                clickable
                :additional-classes="
                  'hover:bg-button-hover rounded ' +
                    (tiptapEditor &&
                      tiptapEditor.getAttributes('heading').level === 1 &&
                      'bg-button-selected')
                "
                @click="
                  tiptapEditor
                    .chain()
                    .focus()
                    .toggleHeading({level: 1})
                    .run()
                "
              />
              <hit-icon
                icon="format_h2"
                clickable
                :additional-classes="
                  'hover:bg-button-hover rounded ' +
                    (tiptapEditor &&
                      tiptapEditor.getAttributes('heading').level === 2 &&
                      'bg-button-selected')
                "
                @click="
                  tiptapEditor
                    .chain()
                    .focus()
                    .toggleHeading({level: 2})
                    .run()
                "
              />
              <hit-icon
                icon="format_h3"
                clickable
                :additional-classes="
                  'hover:bg-button-hover rounded ' +
                    (tiptapEditor &&
                      tiptapEditor.getAttributes('heading').level === 3 &&
                      'bg-button-selected')
                "
                @click="
                  tiptapEditor
                    .chain()
                    .focus()
                    .toggleHeading({level: 3})
                    .run()
                "
              />
              <hit-icon
                icon="format_h4"
                clickable
                :additional-classes="
                  'hover:bg-button-hover rounded ' +
                    (tiptapEditor &&
                      tiptapEditor.getAttributes('heading').level === 4 &&
                      'bg-button-selected')
                "
                @click="
                  tiptapEditor
                    .chain()
                    .focus()
                    .toggleHeading({level: 4})
                    .run()
                "
              />
            </div>
            <div class="flex items-center justify-start mr-4">
              <hit-icon
                icon="format_bold"
                clickable
                :additional-classes="
                  'hover:bg-button-hover rounded ' +
                    (tiptapEditor &&
                      tiptapEditor.isActive('bold') &&
                      'bg-button-selected')
                "
                @click="tiptapEditor.chain().focus().toggleBold().run()"
              />
              <hit-icon
                icon="format_italic"
                clickable
                :additional-classes="
                  'hover:bg-button-hover rounded ' +
                    (tiptapEditor &&
                      tiptapEditor.isActive('italic') &&
                      'bg-button-selected')
                "
                @click="tiptapEditor.chain().focus().toggleItalic().run()"
              />
              <hit-icon
                icon="format_underlined"
                clickable
                :additional-classes="
                  'hover:bg-button-hover rounded ' +
                    (tiptapEditor &&
                      tiptapEditor.isActive('underline') &&
                      'bg-button-selected')
                "
                @click="tiptapEditor.chain().focus().toggleUnderline().run()"
              />
            </div>

            <div class="flex items-center justify-start mr-4">
              <hit-icon
                icon="format_align_left"
                clickable
                additional-classes="hover:bg-button-hover rounded"
                @click="tiptapEditor.chain().focus().setTextAlign('left').run()"
              />
              <hit-icon
                icon="format_align_center"
                clickable
                additional-classes="hover:bg-button-hover rounded"
                @click="
                  tiptapEditor.chain().focus().setTextAlign('center').run()
                "
              />
              <hit-icon
                icon="format_align_right"
                clickable
                additional-classes="hover:bg-button-hover rounded"
                @click="
                  tiptapEditor.chain().focus().setTextAlign('right').run()
                "
              />
              <hit-icon
                icon="format_align_justify"
                clickable
                additional-classes="hover:bg-button-hover rounded"
                @click="
                  tiptapEditor.chain().focus().setTextAlign('justify').run()
                "
              />
            </div>

            <div class="flex items-center justify-start mr-4">
              <hit-icon
                icon="format_list_bulleted"
                clickable
                additional-classes="hover:bg-button-hover rounded"
                @click="tiptapEditor.chain().focus().toggleBulletList().run()"
              />
              <hit-icon
                icon="format_list_numbered"
                clickable
                additional-classes="hover:bg-button-hover rounded"
                @click="tiptapEditor.chain().focus().toggleOrderedList().run()"
              />
            </div>
            <div class="flex items-center justify-start mr-4">
              <hit-dropdown
                :ref="(ref) => (textColorDropdownRef = ref)"
                icon="format_color_text"
                :icon-color-style="textColorIconColorStyle"
                hide-border
                remove-padding
              >
                <div
                  class="flex items-center border-2 m-0.5 cursor-pointer"
                  @click="handleTextColorPicked('default')"
                >
                  <div
                    class="color-button"
                    :style="{
                      'background-color':
                        theme === 'dark-theme' ? '#ffffff' : '#000000',
                    }"
                  />
                  <div>{{ t('hit-components.common.default') }}</div>
                </div>
                <div class="flex flex-col">
                  <div
                    v-for="colorRow of colorList"
                    :key="'col' + colorRow[0]"
                    class="flex"
                  >
                    <div
                      v-for="color of colorRow"
                      :key="color"
                      class="color-button"
                      :style="{
                        'background-color': color,
                      }"
                      @click="handleTextColorPicked(color)"
                    />
                  </div>
                </div>
              </hit-dropdown>
              <hit-dropdown
                :ref="(ref) => (textBgColorDropdownRef = ref)"
                icon="format_color_fill"
                :icon-color-style="bgColorIconColorStyle"
                hide-border
                remove-padding
              >
                <div
                  class="flex items-center border-2 m-0.5 cursor-pointer"
                  @click="handleTextBgColorPicked('default')"
                >
                  <div
                    class="color-button border-2"
                    :style="{
                      'background-color':
                        theme === 'dark-theme' ? '#38373F' : '#ffffff',
                    }"
                  />
                  <div>{{ t('hit-components.common.default') }}</div>
                </div>
                <div class="flex flex-col">
                  <div
                    v-for="colorRow of colorList"
                    :key="'col' + colorRow[0]"
                    class="flex"
                  >
                    <div
                      v-for="color of colorRow"
                      :key="color"
                      class="color-button"
                      :style="{
                        'background-color': color,
                      }"
                      @click="handleTextBgColorPicked(color)"
                    />
                  </div>
                </div>
              </hit-dropdown>
            </div>
            <div
              v-if="standardTexts.length > 0"
              class="flex items-center justify-start mr-4"
            >
              <hit-dropdown
                icon="insert_text"
                hide-border
                remove-padding
              >
                <hit-dropdown-item
                  v-for="textObj of standardTexts"
                  :key="textObj.id"
                  :label="textObj.designation"
                  color="panel-light"
                  @itemClicked="insertStandardText(textObj.description)"
                />
              </hit-dropdown>
            </div>
            <div class="flex items-center justify-start mr-4">
              <slot name="additionalButtons" />
            </div>
          </div>
        </div>
        <div
          class="flex-grow rounded min-h-40 max-h-96 hover:cursor-text overflow-auto"
          @mousedown="handleMouseDown"
          @mouseup="handleMouseUp"
          @scroll="noFocusAfterScroll"
        >
          <editor-content
            :editor="tiptapEditor"
            @mousedown="handleMouseDownEditorContent"
            @mouseup="handleMouseUpEditorContent"
          />
        </div>
      </div>
    </on-click-outside>
    <template #help>
      <!-- @slot Help of the input -->
      <slot name="help" />
    </template>
  </hit-base-input>
</template>

<script>
import HitBaseInput from './HitBaseInput';
import HitFormValidationMixin from '../../../mixins/form/HitFormValidationMixin';
import HitInputMixin from '../../../mixins/form/HitInputMixin';

import {Editor, EditorContent} from '@tiptap/vue-3';
import {Bold} from '@tiptap/extension-bold';
import {BulletList} from '@tiptap/extension-bullet-list';
import {Color} from '@tiptap/extension-color';
import {Document} from '@tiptap/extension-document';
import {Heading} from '@tiptap/extension-heading';
import {Highlight} from '@tiptap/extension-highlight';
import {History} from '@tiptap/extension-history';
import {HorizontalRule} from '@tiptap/extension-horizontal-rule';
import {Image} from '@tiptap/extension-image';
import {Italic} from '@tiptap/extension-italic';
import {ListItem} from '@tiptap/extension-list-item';
import {OrderedList} from '@tiptap/extension-ordered-list';
import {Paragraph} from '@tiptap/extension-paragraph';
import {Text} from '@tiptap/extension-text';
import {TextAlign} from '@tiptap/extension-text-align';
import {TextStyle} from '@tiptap/extension-text-style';
import {Underline} from '@tiptap/extension-underline';

import {HitIcon} from '../../icon';
import {HitDropdown, HitDropdownItem} from '../../dropdown';
import {useI18n} from 'vue-i18n';
import {OnClickOutside} from '@vueuse/components';

export default {
  name: 'HitInputRichText',
  components: {
    HitDropdownItem,
    HitBaseInput,
    HitIcon,
    EditorContent,
    HitDropdown,
    OnClickOutside,
  },
  mixins: [HitInputMixin, HitFormValidationMixin],
  inheritAttrs: false,
  props: {
    /**
     * Value of the input
     */
    value: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Limit the text box of the rich input to a max height
     */
    maxHeight: {
      type: String,
      default: '300px',
    },
    addTopMargin: Boolean,
    allowImages: {
      type: Boolean,
      default: false,
    },
    standardTexts: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    theme: {
      type: String,
      required: false,
      default: 'standard',
    },
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
  data() {
    return {
      tiptapEditor: null,
      textColorDropdownRef: null,
      textBgColorDropdownRef: null,
      triggerChangeOnNextClickOutside: false,
      focusEditorOnMouseUp: false,
      currentTextColor: null,
      currentFillColor: null,
      lastCursorPos: null,
      colorList: [
        // [
        //   '#000000',
        //   '#111111',
        //   '#1C1C1C',
        //   '#333333',
        //   '#666666',
        //   '#808080',
        //   '#999999',
        //   '#B2B2B2',
        //   '#CCCCCC',
        //   '#DDDDDD',
        //   '#EEEEEE',
        //   '#FFFFFF',
        // ],
        [
          '#FFFF00',
          '#FFBF00',
          '#FF8000',
          '#FF4000',
          '#FF0000',
          '#BF0041',
          '#800080',
          '#55308D',
          '#2A6099',
          '#158466',
          '#00A933',
          '#81D41A',
        ],
        [
          '#FFFFD7',
          '#FFF5CE',
          '#FFDBB6',
          '#FFD8CE',
          '#FFD7D7',
          '#F7D1D5',
          '#E0C2CD',
          '#DEDCE6',
          '#DEE6EF',
          '#DEE7E5',
          '#DDE8CB',
          '#F6F9D4',
        ],
        [
          '#FFFFA6',
          '#FFE994',
          '#FFB66C',
          '#FFAA95',
          '#FFA6A6',
          '#EC9BA4',
          '#BF819E',
          '#B7B3CA',
          '#B4C7DC',
          '#B3CAC7',
          '#AFD095',
          '#E8F2A1',
        ],
        [
          '#FFFF6D',
          '#FFDE59',
          '#FF972F',
          '#FF7B59',
          '#FF6D6D',
          '#E16173',
          '#A1467E',
          '#8E86AE',
          '#729FCF',
          '#81ACA6',
          '#77BC65',
          '#D4EA6B',
        ],
        [
          '#FFFF38',
          '#FFD428',
          '#FF860D',
          '#FF5429',
          '#FF3838',
          '#D62E4E',
          '#8D1D75',
          '#6B5E9B',
          '#5983B0',
          '#50938A',
          '#3FAF46',
          '#BBE33D',
        ],
        [
          '#E6E905',
          '#E8A202',
          '#EA7500',
          '#ED4C05',
          '#F10D0C',
          '#A7074B',
          '#780373',
          '#5B277D',
          '#3465A4',
          '#168253',
          '#069A2E',
          '#5EB91E',
        ],
        [
          '#ACB20C',
          '#B47804',
          '#B85C00',
          '#BE480A',
          '#C9211E',
          '#861141',
          '#650953',
          '#55215B',
          '#355269',
          '#1E6A39',
          '#127622',
          '#468A1A',
        ],
        [
          '#706E0C',
          '#784B04',
          '#7B3D00',
          '#813709',
          '#8D281E',
          '#611729',
          '#4E102D',
          '#481D32',
          '#383D3C',
          '#28471F',
          '#224B12',
          '#395511',
        ],
        [
          '#443205',
          '#472702',
          '#492300',
          '#4B2204',
          '#50200C',
          '#41190D',
          '#3B160E',
          '#3A1A0F',
          '#362413',
          '#302709',
          '#2E2706',
          '#342A06',
        ],
      ],
    };
  },
  computed: {
    bgColorIconColorStyle() {
      return this.getIconColorStyle(this.currentFillColor, false);
    },
    textColorIconColorStyle() {
      return this.getIconColorStyle(this.currentTextColor, true);
    },
  },
  watch: {
    value: {
      handler(newValue) {
        this.tiptapEditor.commands.setContent(newValue);
      },
    },
    disabled: {
      handler(newValue) {
        this.tiptapEditor.options.editable = !newValue;
      },
    },
  },
  beforeUnmount() {
    this.tiptapEditor.destroy();
    window.removeEventListener('blur', this.handleWindowBlur);
  },
  mounted() {
    this.tiptapEditor = new Editor({
      editable: !this.disabled,
      extensions: [
        Bold,
        BulletList,
        Color,
        Document,
        Heading,
        Highlight.configure({multicolor: true}),
        History.configure({
          newGroupDelay: 1000,
        }),
        HorizontalRule,
        ...(this.allowImages ? [Image.configure({allowBase64: true})] : []),
        Italic,
        ListItem,
        OrderedList,
        Paragraph,
        Text,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
          alignments: ['left', 'center', 'right', 'justify'],
        }),
        TextStyle,
        Underline,
      ],
    });
    this.tiptapEditor.on('update', ({editor}) => {
      this.$emit('input', editor.getHTML());
    });
    this.tiptapEditor.on('focus', () => {
      this.$emit('focus');
      this.ignoreFocusLost = false;
    });
    this.tiptapEditor.on('transaction', (transaction) => {
      this.lastCursorPos = transaction?.transaction?.curSelection?.$anchor?.pos;
      this.currentTextColor = this.getActiveTextColor();
      this.currentFillColor = this.getActiveFillColor();
    });
    window.addEventListener('blur', this.handleWindowBlur);
    this.tiptapEditor.commands.setContent(this.value);
  },
  methods: {
    noFocusAfterScroll() {
      this.focusEditorOnMouseUp = false;
    },
    handleMouseDown() {
      if (!this.disabled) {
        this.focusEditorOnMouseUp = true;
      }
    },
    handleMouseUp() {
      if (!this.disabled && this.focusEditorOnMouseUp) {
        this.tiptapEditor.commands.focus('end');
        this.focusEditorOnMouseUp = false;
      }
    },
    handleMouseDownEditorContent($event) {
      $event.stopPropagation();
    },
    handleMouseUpEditorContent($event) {
      $event.stopPropagation();
    },

    handleTextColorPicked(color) {
      if (color === 'default') {
        this.tiptapEditor.chain().focus().unsetColor().run();
      } else {
        this.tiptapEditor.chain().focus().setColor(color).run();
      }
      this.textColorDropdownRef.hideDropdown();
    },
    handleTextBgColorPicked(color) {
      if (color === 'default') {
        this.tiptapEditor.chain().focus().unsetHighlight().run();
      } else {
        this.tiptapEditor.chain().focus().toggleHighlight({color: color}).run();
      }
      this.textBgColorDropdownRef.hideDropdown();
    },
    handleBlur() {
      this.triggerChangeOnNextClickOutside = true;
    },
    handleWindowBlur() {
      this.fireInputChange(this.tiptapEditor.getHTML());
      this.triggerChangeOnNextClickOutside = false;
    },
    handleClickOutside() {
      if (this.triggerChangeOnNextClickOutside) {
        this.fireInputChange(this.tiptapEditor.getHTML());
        this.triggerChangeOnNextClickOutside = false;
      }
    },
    insertStandardText(textValue) {
      this.tiptapEditor
        .chain()
        .focus()
        .insertContentAt(this.lastCursorPos || 0, textValue)
        .run();
    },
    getIconColorStyle(underlineColor, forTextColor) {
      let isDarkTheme = this.theme === 'dark-theme';
      let defaultUnderlineColor = 'black';
      //for dark theme, text color is white and bg color is dark byy default
      if ((forTextColor && isDarkTheme) || (!forTextColor && !isDarkTheme)) {
        defaultUnderlineColor = 'white';
      }
      return {
        background:
          '-webkit-linear-gradient(bottom,' +
          (underlineColor || defaultUnderlineColor) +
          ' 20%' +
          ', ' +
          (isDarkTheme ? 'white' : 'black') +
          ' 20%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
      };
    },
    getActiveTextColor() {
      //no efficient way to check active color but 100 loop iterations dont really matter
      for (const colorRow of this.colorList) {
        for (const colorValue of colorRow) {
          if (this.tiptapEditor.isActive('textStyle', {color: colorValue})) {
            return colorValue;
          }
        }
      }
      const color = document?.getSelection()?.anchorNode?.parentNode?.style
        ?.color;
      if (color !== 'inherit') {
        return color;
      } else {
        return null;
      }
    },
    getActiveFillColor() {
      //no efficient way to check active color but 100 loop iterations dont really matter
      for (const colorRow of this.colorList) {
        for (const colorValue of colorRow) {
          if (this.tiptapEditor.isActive('highlight', {color: colorValue})) {
            return colorValue;
          }
        }
      }
      const color = document?.getSelection()?.anchorNode?.parentNode?.style
        ?.backgroundColor;
      if (color !== 'inherit') {
        return color;
      } else {
        return null;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.tiptap {
  height: 100%; /* Fill the container vertically */
  width: 100%; /* Fill the container vertically */
  border: none; /* Remove the border */
  padding: 0.5rem;
  margin: 0;

  ul {
    list-style-position: inside;
    li {
      p {
        display: inline;
      }
    }
  }

  ol {
    list-style-type: decimal;
    list-style-position: inside;
    li {
      p {
        display: inline;
      }
    }
  }

  h1 {
    display: block;
    font-size: 2em;
    margin-top: 0.67em;
    margin-bottom: 0.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }
  h2 {
    display: block;
    font-size: 1.5em;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }
  h3 {
    display: block;
    font-size: 1.17em;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }
  h4 {
    display: block;
    margin-top: 1.33em;
    margin-bottom: 1.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }
}

.tiptap:focus {
  outline: none; /* Remove outline when focused */
}

.color-button {
  width: 20px; /* Adjust size as needed */
  height: 20px; /* Adjust size as needed */
  margin: 2px; /* Adjust margin as needed */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.color-button:hover {
  filter: brightness(90%); /* Adjust brightness on hover as needed */
}
</style>
