<template>
  <div
    :style="cssProps"
    class="hit-table"
  >
    <div
      v-if="headers"
      class="hit-table-header border-b border-table"
    >
      <hit-table-cell v-if="draggable" />
      <hit-table-cell
        header
        input
      >
        <div
          :class="{
            'min-h-6': hasFiltersActive || (sort.property && sort.order),
          }"
        />
        <hit-input-checkbox
          v-if="mustShowCheckBoxes"
          :indeterminate="allItemsSelected === undefined"
          :value="allItemsSelected"
          :disabled="workInProgress || tabLinesToDisplay.length === 0"
          :remove-padding="true"
          use-custom-height
          class="hit-table-header-checkbox"
          @change="onAllItemsSelectedChange"
        />
      </hit-table-cell>
      <hit-table-cell
        v-for="itemProperty in itemPropertiesToDisplay"
        :key="itemProperty.header"
        header
        @click-header="(rootDom) => handleHeaderClick(rootDom, itemProperty)"
      >
        <div
          class="flex flex-col w-full"
          :class="{'items-end': itemProperty.type === 'decimal'}"
          style="position: relative"
        >
          <span
            class="w-full"
            :class="{
              'text-right': ['decimal', 'int', 'number'].includes(
                itemProperty.type
              ),
            }"
          >
            {{
              itemProperty.header
                ? itemProperty.header.replace('$linbrk$', '-')
                : ''
            }}
          </span>
          <span
            class="flex items-end overflow-y-visible"
            :style="iconsPosition(itemProperty)"
            :class="{
              'min-h-6': hasFiltersActive || (sort.property && sort.order),
            }"
          >
            <hit-icon
              v-if="
                userFilters &&
                  userFilters[itemProperty.propertyName] &&
                  Object.keys(userFilters[itemProperty.propertyName]).length > 0
              "
              icon="filter"
              size="xs"
            />
            <hit-icon
              v-if="sort.property === itemProperty.propertyName"
              :icon="sort.order === 'asc' ? 'sort-up' : 'sort-down'"
              size="xs"
            />
          </span>
        </div>
      </hit-table-cell>
      <hit-table-cell header>
        <div
          class="w-full flex"
          :class="{
            'justify-start': customColumnAlignment === 'start',
            'justify-center': customColumnAlignment === 'center',
            'justify-end': customColumnAlignment === 'end',
          }"
        >
          <slot name="customColumnHeader" />
        </div>
      </hit-table-cell>
    </div>
    <div class="hit-table-content">
      <div
        v-for="(tabLine, lineIndex) in tabLinesToDisplay"
        :key="getItemIdentifier(tabLine.item)"
        :ref="(el) => (lineRefs[getItemIdentifier(tabLine.item)] = el)"
        :class="getLineClass(tabLine.item, additionalLine)"
        @click="editItem(getItemIdentifier(tabLine.item))"
        @mousedown.middle="openInNewTab(getItemIdentifier(tabLine.item))"
        @drop="
          (evt) =>
            handleDrop(
              evt,
              lineRefs[getItemIdentifier(tabLine.item)],
              tabLine.item
            )
        "
        @dragenter="
          (evt) => {
            handleDragEnter(evt, getItemIdentifier(tabLine.item));
          }
        "
        @dragleave="
          (evt) => {
            handleDragLeave(evt, getItemIdentifier(tabLine.item));
          }
        "
        @dragover="(evt) => evt.preventDefault()"
      >
        <slot
          name="row"
          :tab-line="tabLine"
          :item-properties-to-display="itemPropertiesToDisplay"
        >
          <slot
            v-if="tabLine.editable"
            name="editable-row"
            :tab-line="tabLine"
            :item-properties-to-display="itemPropertiesToDisplay"
            :disable-editing-fn="disableEditing"
            :row-validations="validations"
          >
            <hit-form
              :value="tabLine.item"
              :autosave="autoSave"
              :cancellable="true"
              :validations="validations"
              :before-save-fn="beforeSaveFn"
              buttons-on-same-line
              render-as-table
              disable-watcher
              @updated="disableEditing"
              @cancel="disableEditing"
              @auto-save="(value) => $emit('save', value)"
              @saveAll="
                (value) => {
                  disableEditing();
                  $emit('saveLine', value);
                }
              "
            >
              <template
                #default="{
                  formData,
                  validationState,
                  cancelFn,
                  saveFn,
                  canSave,
                  manualFieldChange,
                }"
              >
                <hit-table-row>
                  <hit-table-cell v-if="draggable" />
                  <hit-table-cell input />
                  <hit-table-cell
                    v-for="(itemProperty, itemIndex) in itemPropertiesToDisplay"
                    :key="itemProperty.propertyName"
                    :right-alignment="
                      ['decimal', 'int', 'number'].includes(itemProperty.type)
                    "
                    :input="!itemProperty.readOnly"
                    edit-mode
                  >
                    <slot
                      :name="
                        generateCustomSlotName(itemProperty.propertyName, true)
                      "
                      :line-index="lineIndex"
                      :item-property="itemProperty"
                      :form-data="formData"
                      :validation-state="validationState"
                      :save-fn="saveFn"
                      :manual-field-change="manualFieldChange"
                    >
                      <hit-input-generic
                        v-model:value="formData[itemProperty.propertyName]"
                        :disabled="itemProperty.readOnly"
                        :name="itemProperty.propertyName"
                        :value-type="
                          getValueType(itemProperty.propertyName, formData)
                        "
                        :validation-state="
                          validationState[itemProperty.propertyName]
                        "
                        :inline-input="true"
                        :instant-focus="itemIndex === 0"
                        class="w-full"
                      />
                    </slot>
                  </hit-table-cell>
                  <hit-table-cell>
                    <div class="flex items-center align-end gap-1">
                      <hit-button
                        prefix-icon="check"
                        icon-size="base"
                        remove-padding
                        color="success"
                        :disabled="!canSave"
                        @click="saveFn"
                      />
                      <hit-button
                        v-if="!autoSave"
                        prefix-icon="clear"
                        icon-size="base"
                        remove-padding
                        color="warning"
                        @click="cancelFn"
                      />
                    </div>
                  </hit-table-cell>
                </hit-table-row>
              </template>
            </hit-form>
          </slot>
          <slot
            v-else
            name="read-only-row"
            :tab-line="tabLine"
            :item-properties-to-display="itemPropertiesToDisplay"
            :edit-item-fn="editItem"
            :select-item-fn="selectItem"
            :unselect-item-fn="unselectItem"
            :selectable="mustShowCheckBoxes"
          >
            <hit-table-row
              @click="editItem(getItemIdentifier(tabLine.item))"
              @mousedown.middle.stop="
                openInNewTab(getItemIdentifier(tabLine.item))
              "
            >
              <hit-table-cell
                v-if="draggable"
                :style="{'padding-right': '0px', 'padding-left': '0px'}"
              >
                <hit-button
                  v-if="sortingAllowed"
                  prefix-icon="drag-handle"
                  class="grabbable border-none"
                  :draggable="true"
                  @dragstart="(evt) => handleDragStart(evt, tabLine.item)"
                  @dragend="handleDragEnd"
                />
              </hit-table-cell>
              <hit-table-cell input>
                <hit-input-checkbox
                  v-if="mustShowCheckBoxes"
                  class="hit-table-item-checkbox"
                  :value="tabLine.selected"
                  :disabled="workInProgress"
                  :remove-padding="true"
                  use-custom-height
                  @change="
                    $event
                      ? selectItem(tabLine.item)
                      : unselectItem(tabLine.item)
                  "
                  @click.stop
                />
              </hit-table-cell>
              <hit-table-cell
                v-for="itemProperty in itemPropertiesToDisplay"
                :key="itemProperty.propertyName"
                :right-alignment="
                  ['decimal', 'int', 'number'].includes(itemProperty.type)
                "
              >
                <slot
                  :name="generateCustomSlotName(itemProperty.propertyName)"
                  :line-index="lineIndex"
                  :form-data="tabLine.item"
                  :item-property="itemProperty"
                  :validation-state="{}"
                >
                  <hit-input-generic-read-only
                    :value="tabLine.item[itemProperty.propertyName]"
                    :value-type="
                      getValueType(itemProperty.propertyName, tabLine.item)
                    "
                  />
                </slot>
              </hit-table-cell>
              <hit-table-cell v-if="$slots.customColumnContent">
                <div
                  class="flex w-full"
                  :class="{
                    'justify-start': customColumnAlignment === 'start',
                    'justify-center': customColumnAlignment === 'center',
                    'justify-end': customColumnAlignment === 'end',
                  }"
                >
                  <slot
                    name="customColumnContent"
                    :form-data="tabLine.item"
                  />
                </div>
              </hit-table-cell>
            </hit-table-row>
          </slot>
        </slot>
      </div>
      <div
        v-if="itemBeingCreated && inlineEditionAllowed"
        class="hit-table-line-new-item"
      >
        <slot
          name="creation-row"
          :item-properties-to-display="itemPropertiesToDisplay"
          :disable-creating-fn="disableCreating"
          :new-item="newItem"
          :row-validations="validations"
        >
          <hit-form
            :value="newItem"
            :autosave="autoSave"
            :cancellable="true"
            :validations="validations"
            :before-save-fn="beforeSaveFn"
            buttons-on-same-line
            focus-first-input
            render-as-table
            disable-watcher
            @created="disableCreating"
            @cancel="disableCreating"
            @saveAll="
              (value) => {
                disableCreating();
                $emit('insertLine', value);
              }
            "
          >
            <template
              #default="{
                formData,
                validationState,
                cancelFn,
                saveFn,
                canSave,
                manualFieldChange,
              }"
            >
              <hit-table-row>
                <hit-table-cell v-if="draggable" />
                <hit-table-cell input />
                <hit-table-cell
                  v-for="(itemProperty, itemIndex) in itemPropertiesToDisplay"
                  :key="itemProperty.propertyName"
                  edit-mode
                  input
                >
                  <slot
                    :name="
                      generateCustomSlotName(itemProperty.propertyName, true)
                    "
                    :item-property="itemProperty"
                    :form-data="formData"
                    :validation-state="validationState"
                    :manual-field-change="manualFieldChange"
                  >
                    <hit-input-generic
                      v-model:value="formData[itemProperty.propertyName]"
                      :disabled="itemProperty.readOnly"
                      :name="itemProperty.propertyName"
                      :value-type="
                        getValueType(itemProperty.propertyName, formData)
                      "
                      :validation-state="
                        validationState[itemProperty.propertyName]
                      "
                      :inline-input="true"
                      :instant-focus="itemIndex === 0"
                      class="w-full"
                    />
                  </slot>
                </hit-table-cell>
                <hit-table-cell>
                  <div class="flex items-center align-end gap-1">
                    <hit-button
                      prefix-icon="check"
                      icon-size="base"
                      remove-padding
                      color="success"
                      :disabled="!canSave"
                      @click="saveFn"
                    />
                    <hit-button
                      prefix-icon="clear"
                      icon-size="base"
                      remove-padding
                      color="warning"
                      @click="cancelFn"
                    />
                  </div>
                </hit-table-cell>
              </hit-table-row>
            </template>
            <template
              v-for="({}, slot) of $slots"
              #[slot]="scope"
            >
              <slot
                :name="slot"
                v-bind="scope"
              />
            </template>
          </hit-form>
        </slot>
      </div>
      <div
        v-if="additionalLine"
        class="last-hit-table-line-item"
      >
        <div
          class="hit-table-row bg-table hover:bg-table-hover border-t border-table hit-table-row-normal"
        >
          <hit-table-cell v-if="draggable" />
          <hit-table-cell input />
          <hit-table-cell
            v-for="itemProperty in itemPropertiesToDisplay"
            :key="itemProperty.propertyName"
          >
            <slot
              :name="
                generateCustomSlotName(itemProperty.propertyName) +
                  '.additional'
              "
              :item-property="itemProperty"
              :form-data-table="
                tabLinesToDisplay.map((tabLine) => tabLine.item)
              "
            />
          </hit-table-cell>
        </div>
      </div>
    </div>
    <Teleport to="#root-element">
      <OnClickOutside
        v-if="headerDropdownVisible"
        @trigger="handleHeaderControlClickOutside"
      >
        <div class="filter-dropdown">
          <hit-header-control
            :header-root-dom="headerDropdownHeaderDom"
            :item-property="headerDropdownProperty"
            :sort="sort"
            :filters="userFilters[headerDropdownProperty.propertyName]"
            @set-sort="(value) => setSort(headerDropdownProperty, value)"
            @add-filter="
              (type, value) =>
                handleAddFilter(headerDropdownProperty, type, value)
            "
            @change-filter="
              (value, type, index) =>
                handleChangeFilter(headerDropdownProperty, value, type, index)
            "
            @delete-filter="
              (type, index) =>
                handleDeleteFilter(headerDropdownProperty, type, index)
            "
          />
        </div>
      </OnClickOutside>
    </Teleport>
  </div>
</template>

<script>
import HitTableCell from './HitTableCell.vue';
import HitTableRow from './HitTableRow.vue';
import HitTableDataMixin from './HitTableDataMixin.js';
import HitLoadingReceiverMixin from '../../mixins/loading/HitLoadingReceiverMixin';
import {HitInputGenericReadOnly} from '../form';
import {useI18n} from 'vue-i18n';
import {HitInputCheckbox, HitForm, HitInputGeneric} from '../form';
import HitButton from '../button/HitButton.vue';
import HitHeaderControl from './filters/HitHeaderControl.vue';
import {OnClickOutside} from '@vueuse/components';
import {HitIcon} from '../icon';

const SORT_ORDER_ASC = 'asc';
const SORT_ORDER_DESC = 'desc';

export default {
  name: 'HitTableDataGrid',
  components: {
    HitHeaderControl,
    HitButton,
    HitInputCheckbox,
    HitForm,
    HitInputGeneric,
    HitTableCell,
    HitTableRow,
    HitInputGenericReadOnly,
    OnClickOutside,
    HitIcon,
  },
  mixins: [HitTableDataMixin, HitLoadingReceiverMixin],
  inject: {
    disableCreating: {
      default: undefined,
    },
    disableEditing: {
      default: undefined,
    },
  },
  props: {
    headers: Boolean,
    itemBeingEdited: {
      type: Object,
      default: null,
    },
    newItem: {
      type: Object,
      default: null,
    },
    isLoading: Boolean,
    sort: {
      type: Object,
      default: null,
    },
    selectedItems: {
      type: Array,
      default: null,
    },
    tabLines: {
      type: Array,
      default: null,
    },
    itemBeingCreated: Boolean,
    autoRowHeight: Boolean,
    draggable: {
      type: Boolean,
      required: false,
      default: false,
    },
    additionalLine: {
      type: Boolean,
      required: false,
      default: false,
    },
    customColumnWidth: {
      type: String,
      required: false,
      default: '1fr',
    },
    customColumnAlignment: {
      type: String,
      required: false,
      default: 'end',
      validator: (value) => {
        return ['end', 'center', 'start'].indexOf(value) !== -1;
      },
    },
    beforeSaveFn: {
      type: Function,
      default() {
        return null;
      },
    },
    userFilters: {
      type: Object,
      required: false,
      default: () => {},
    },
    inlineEditionAllowed: Boolean,
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
  data() {
    return {
      lineRefs: {}, // contains references to the dom elements of every tab line item
      dragCounters: {}, // contains counters to count the number of dragenter and dragleave events of every line. needed because the tabline item doesnt contain a single pixel so the event is only fired on children
      indexById: {}, // needed for drag and drop ordering, to know if border should be drawn bottom or top
      draggedItem: null,
      headerDropdownProperty: null, // either null or header string of the visible header
      headerDropdownHeaderDom: null,
      headerDropdownVisible: false,
    };
  },
  computed: {
    selectColumnWidth() {
      let value = 0;
      if (this.itemsSelectable) {
        value += 1.75;
      }
      return `${value}rem`;
    },
    cssProps() {
      let gridTemplateColumns = [];
      if (this.draggable) {
        gridTemplateColumns.push('minmax(0,2.5rem)');
      }
      gridTemplateColumns.push(this.selectColumnWidth);
      this.itemPropertiesToDisplay.forEach((itemProperty) => {
        let minWidth = itemProperty.minWidth ? itemProperty.minWidth : 0;
        let maxWidth = this.computePropertyMaxWidth(itemProperty);
        gridTemplateColumns.push(`minmax(${minWidth}, ${maxWidth})`);
      });
      if (this.$slots.customColumnContent) {
        const minWidth =
          this.inlineEditionAllowed && this.$slots.customColumnContent ? 70 : 0;
        const width = Math.max(minWidth, parseInt(this.customColumnWidth));
        gridTemplateColumns.push(`${width}px`);
      }

      if (this.inlineEditionAllowed && !this.$slots.customColumnContent) {
        gridTemplateColumns.push('minmax(0,70px)');
      }

      return {
        '--grid-template-columns': gridTemplateColumns.join(' '),
        '--select-column-width': this.selectColumnWidth,
      };
    },
    sortIcon() {
      if (this.sort.order === SORT_ORDER_ASC) {
        return 'sort-up';
      } else if (this.sort.order === SORT_ORDER_DESC) {
        return 'sort-down';
      } else {
        return '';
      }
    },
    noSelectedItems() {
      return this.selectedItems.length;
    },
    allItemsSelected() {
      if (this.noSelectedItems === 0) {
        return false;
      } else if (this.noSelectedItems === this.tabLines.length) {
        return true;
      } else {
        return undefined;
      }
    },
    noHeadersClick() {
      switch (this.sort.order) {
        case SORT_ORDER_ASC:
          return 1;
        case SORT_ORDER_DESC:
          return 2;
        default:
          return 0;
      }
    },
    hasFiltersActive() {
      if (!this.userFilters) {
        return false;
      }
      let filtersActive = false;
      Object.keys(this.userFilters).forEach((propKey) => {
        if (
          this.userFilters[propKey] &&
          Object.keys(this.userFilters[propKey]).length > 0
        ) {
          filtersActive = true;
        }
      });
      return filtersActive;
    },
    sortingAllowed() {
      return !this.sort.property && !this.sort.order && !this.hasFiltersActive;
    },
  },
  methods: {
    iconsPosition(attribute) {
      if (attribute.type === 'decimal') {
        return 'position:absolute; right: -5px;top: -20px;';
      } else {
        return 'position:absolute; left: -5px;top: -20px;';
      }
    },
    handleAddFilter(itemProperty, type, value) {
      const filters = {...this.userFilters};
      if (!filters[itemProperty.propertyName]) {
        filters[itemProperty.propertyName] = {};
      }
      if (type === '≠' || type === '∋' || type === '∌') {
        if (!filters[itemProperty.propertyName][type]) {
          filters[itemProperty.propertyName][type] = [];
        }
        filters[itemProperty.propertyName][type].push(value);
      } else {
        filters[itemProperty.propertyName][type] = null;
      }
      Object.assign(this.userFilters, filters);
      if (value) {
        this.$emit('changeFilter', filters);
      }
    },
    handleChangeFilter(itemProperty, value, type, index) {
      if (
        !this.userFilters[itemProperty.propertyName] ||
        !Object.prototype.hasOwnProperty.call(
          this.userFilters[itemProperty.propertyName],
          type
        )
      ) {
        return;
      }
      if (
        index !== undefined &&
        !Array.isArray(this.userFilters[itemProperty.propertyName][type])
      ) {
        return;
      }

      const filters = {...this.userFilters};
      if (index === undefined) {
        filters[itemProperty.propertyName][type] = value;
      } else {
        filters[itemProperty.propertyName][type].splice(index, 1, value);
      }
      this.$emit('changeFilter', filters);
    },
    handleDeleteFilter(itemProperty, type, index) {
      const tempFilters = {...this.userFilters};
      if (index !== undefined) {
        tempFilters[itemProperty.propertyName][type].splice(index, 1);
        if (tempFilters[itemProperty.propertyName][type].length === 0) {
          delete tempFilters[itemProperty.propertyName][type];
        }
      } else {
        delete tempFilters[itemProperty.propertyName][type];
      }
      if (Object.keys(tempFilters[itemProperty.propertyName]).length === 0) {
        delete tempFilters[itemProperty.propertyName];
      }
      this.$emit('changeFilter', tempFilters);
    },
    handleHeaderControlClickOutside() {
      // the problem is that clicking the header with the dropdown open, triggers both the clickoutside and the header
      // click, which will close the dropdown and open it again instead of just closing it. There isn't a nice way to
      // differentiate between this case and the case where the user clicks outside and then the header. Both trigger
      // a clickOutside and then a header click, but one has to close the dropdown and the other has to open it.
      // another problem of simpler solutions is that clicking another header with one already open doesnt work.
      // TODO: find better solution than timeout.
      this.headerDropdownVisible = false;
      setTimeout(() => {
        if (!this.headerDropdownVisible) {
          this.headerDropdownProperty = null;
        }
      }, 200);
    },
    handleHeaderClick(headerRootDom, itemProperty) {
      if (
        !this.headerDropdownProperty ||
        this.headerDropdownProperty.propertyName !== itemProperty.propertyName
      ) {
        this.headerDropdownHeaderDom = headerRootDom;
        this.headerDropdownProperty = itemProperty;
        this.headerDropdownVisible = true;
      } else {
        this.headerDropdownHeaderDom = null;
        this.headerDropdownProperty = null;
      }
    },
    onAllItemsSelectedChange(selected) {
      this.tabLines.forEach((tabLine) => {
        if (selected) {
          if (!this.selectedItems.includes(tabLine.item)) {
            this.selectItem(tabLine.item);
          }
        } else {
          this.unselectItem(tabLine.item);
        }
      });
    },
    setSort(itemProperty, value) {
      // FIXME has been implemented mutating props by Dieu, needs to be fixed sometime
      // eslint-disable-next-line vue/no-mutating-props
      this.sort.property = itemProperty.propertyName;
      if (value === SORT_ORDER_ASC || value === SORT_ORDER_DESC) {
        // eslint-disable-next-line vue/no-mutating-props
        this.sort.order = value;
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        this.sort.order = null;
        // eslint-disable-next-line vue/no-mutating-props
        this.sort.property = null;
      }
      this.$emit('sort', this.sort);
      this.headerDropdownVisible = false;
      this.headerDropdownHeaderDom = null;
      this.headerDropdownProperty = null;
    },
    computePropertyMaxWidth(itemProperty) {
      if (itemProperty.maxWidth) {
        return itemProperty.maxWidth;
      } else {
        if (itemProperty.propertyName === 'status') {
          return '7.25rem';
        }
        switch (this.getValueType(itemProperty.propertyName)) {
          case 'boolean':
            return '3rem';
          case 'date':
            return '10rem';
          default:
            return '1fr';
        }
      }
    },
    getIndexById(id) {
      if (this.indexById[id]) {
        return this.indexById[id];
      }
      for (let i = 0; i < this.tabLinesToDisplay.length; i++) {
        if (id === this.getItemIdentifier(this.tabLinesToDisplay[i].item)) {
          this.indexById[id] = i;
          return i;
        }
      }
      return -1;
    },
    handleDragStart(evt, item) {
      this.draggedItem = item;
      this.draggedItem.domEl = evt.target.parentNode.parentNode.parentNode;
      evt.dataTransfer.effectAllowed = 'move';
      // if uncommented, uses the whole line as drag image. Unfortunately It obstructs reading the values of other lines and the styling of the dragged image cant be changed.
      // const draggedImgXOffset = evt.target.getBoundingClientRect().x - this.draggedItem.domEl.getBoundingClientRect().x + evt.target.offsetWidth/2
      // const draggedImgYOffset = this.draggedItem.domEl.offsetHeight/2
      // evt.dataTransfer.setDragImage(this.draggedItem.domEl,draggedImgXOffset,draggedImgYOffset)
      this.draggedItem.domEl.children[0].classList.remove(
        'inward-border-transparent'
      );
      this.draggedItem.domEl.children[0].classList.add('inward-border');
      this.$emit('unselect-all');
    },
    handleDragEnd() {
      setTimeout(() => {
        this.draggedItem.domEl.children[0].classList.remove('inward-border');
        this.draggedItem.domEl.children[0].classList.add(
          'inward-border-transparent'
        );
      }, 300);
    },
    handleDragEnter(evt, id) {
      const domEl = this.lineRefs[id];
      if (
        domEl.children &&
        domEl.children[0] &&
        domEl.children[0].classList.contains('inward-border')
      ) {
        return;
      }
      if (!this.dragCounters[id]) {
        this.dragCounters[id] = 1;
      } else {
        this.dragCounters[id] += 1;
      }
      if (this.dragCounters[id] === 1) {
        evt.preventDefault();
        if (!this.draggedItem || !this.getItemIdentifier(this.draggedItem)) {
          return;
        }
        evt.dataTransfer.dropEffect = 'move';
        const draggedId = this.getItemIdentifier(this.draggedItem);
        // draw border at the bottom for items below and at the top for items above
        let borderBottom = this.getIndexById(id) > this.getIndexById(draggedId);
        if (borderBottom && domEl.nextElementSibling) {
          domEl.nextElementSibling.children[0].classList.add('drag-border-top');
        } else {
          if (domEl.classList.contains('first-hit-table-line-item')) {
            domEl.parentNode.previousElementSibling.classList.add(
              'header-border-drag'
            );
          } else {
            domEl.children[0].classList.add('drag-border-top');
          }
        }
      }
    },
    handleDragLeave(evt, id) {
      const domEl = this.lineRefs[id];
      if (
        domEl.children &&
        domEl.children[0] &&
        domEl.children[0].classList.contains('inward-border')
      ) {
        return;
      }
      if (this.dragCounters[id]) {
        this.dragCounters[id] -= 1;
      }
      if (this.dragCounters[id] === 0) {
        evt.preventDefault();
        if (!this.draggedItem || !this.getItemIdentifier(this.draggedItem)) {
          return;
        }
        const draggedId = this.getItemIdentifier(this.draggedItem);
        // remove border at the bottom for items below and at the top for items above
        let borderBottom = this.getIndexById(id) > this.getIndexById(draggedId);
        if (borderBottom) {
          domEl.nextElementSibling.children[0].classList.remove(
            'drag-border-top'
          );
        } else {
          if (domEl.classList.contains('first-hit-table-line-item')) {
            domEl.parentNode.previousElementSibling.classList.remove(
              'header-border-drag'
            );
          } else {
            domEl.children[0].classList.remove('drag-border-top');
          }
        }
      }
    },
    handleDrop(evt, droppedOnDomEl, droppedOnItem) {
      if (!this.draggedItem) return;
      for (let id in this.lineRefs) {
        if (!this.lineRefs[id]) {
          //if the dom element no longer exists, clean it
          delete this.lineRefs[id];
          continue;
        }
        this.lineRefs[id].children[0].classList.remove('drag-border-top');
        if (this.lineRefs[id].nextElementSibling) {
          this.lineRefs[id].nextElementSibling.children[0].classList.remove(
            'drag-border-top'
          );
        }
      }
      droppedOnDomEl.parentNode.previousElementSibling.classList.remove(
        'header-border-drag'
      );
      for (let id in this.dragCounters) {
        this.dragCounters[id] = 0;
      }
      this.indexById = {};
      if (droppedOnDomEl.parentNode === this.draggedItem.domEl.parentNode) {
        evt.preventDefault();
        this.$emit('move', this.draggedItem, droppedOnItem);
      }
    },
  },
};
</script>

<style lang="scss">
.hit-table {
  @apply text-table;
  min-height: 2rem;
  grid-area: hit-table;
  overflow-y: hidden;
  padding-top: 6px;
  display: grid;
  grid-template-rows: max-content 1fr;
}

.hit-table-header {
  grid-template-columns: var(--grid-template-columns);
  gap: 8px;
  scrollbar-gutter: stable;
  overflow-y: auto;
  padding-right: 0.375rem;
  padding-left: 0.375rem;
  @apply grid py-1 min-h-12 cursor-pointer font-medium uppercase;
}

.table-cell.hit-table-current-sort {
  @apply text-table;
}

.hit-table-header .hit-table-cell:hover .hit-table-sort-preview i {
  @apply text-table opacity-50;
}

.hit-table-content {
  grid-template-columns: auto;
  scrollbar-gutter: stable;
  overflow-y: auto;
  @apply grid max-h-full;
}

.hit-table-cell {
  @apply overflow-hidden;
}

.hit-table-header-checkbox,
.hit-table-item-checkbox {
  @apply ml-1;
}

.header-border-drag {
  border-color: #0a0 !important;
}

.decimal-header {
  //width: 215px;
  //width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.classic-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
</style>
